import { createNamespacedHelpers } from 'vuex';
import { GET_PREPARE_FORM } from '@/core/services/store/calender.module';
const { mapActions, mapState } = createNamespacedHelpers('calender');
import { CALENDAR_TYPE } from '@/core/plugins/constants.js';

export const commentMixin = {
  data() {
    return {
      dateFomartion: 'DD/MM/YYYY',
      nowDate: '',
      nowTime: '',

      timeOptions: [
        { text: 'Ngày', value: 0 },
        { text: 'Tuần', value: 1 },
        { text: 'Tháng', value: 2 },
      ],
    };
  },

  computed: {
    ...mapState(['coaches', 'patients', 'formRepeatTypes', 'formTypes']),

    optionTypes() {
      return this.formTypes.filter((item) => {
        return item.value !== CALENDAR_TYPE.INPUT_RATING.value;
      });
    },
  },

  created() {
    this.setDateBefore();
  },

  methods: {
    ...mapActions({ GET_PREPARE_FORM }),

    setDateBefore() {
      const d = new Date();
      this.nowDate = d.setDate(d.getDate() - 1);
    },

    setCurrentTime() {
      this.nowTime = this.getTimeOfDate({
        date: new Date(),
        showSecond: true,
      });
    },

    getDurationTimes(start, end) {
      return this.convertTimeToSecond(end) - this.convertTimeToSecond(start);
    },

    getDateSeconds(date) {
      return this.formatDate(date).valueOf() / 1000;
    },

    getDayText(day) {
      switch (day) {
        case 0:
          return 'chủ nhật';
        case 1:
          return 'thứ 2';
        case 2:
          return 'thứ 3';
        case 3:
          return 'thứ 4';
        case 4:
          return 'thứ 5';
        case 5:
          return 'thứ 6';
        case 6:
          return 'thứ 7';

        default:
          return 'chủ nhật';
      }
    },

    isAfterToday(date) {
      const d = this.formatDate(date);
      const today = this.formatDate();

      if (d.diff(today, 'days') === 0) return d.date() > today.date();
      return d.diff(today, 'days') > 0;
    },

    formatDate(date) {
      if (!date) return this.$moment();
      return this.$moment(date, 'DD/MM/YYYY HH:mm:ss');
    },

    formatShortDate(date) {
      if (!date) return this.$moment();
      return this.$moment(date).format('DD/MM/YYYY');
    },
  },
};
